import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import {
  Card,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { getImage, StaticImage } from "gatsby-plugin-image"
import Carousel from "../components/carousel/Carousel"
import { graphql } from "gatsby"

interface iImageNode {
  node: {
    name: string
    relativePath: string
    childImageSharp: any
  }
}

const IndexPage = ({ data }: { data: any }) => {
  const theme = useTheme()
  const classes = makeStyles({
    paper: {
      padding: theme.spacing(4),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    adsWrapper: {
      textAlign: "center",
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    adCard: {
      maxWidth: 250,
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      minHeight: 159,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    adsGridContainer: {
      "& > div": {
        display: "flex",
        justifyContent: "center",
      },
    },
  })()

  const [menuOpen, toggleMenu] = React.useState(false)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const nodes: iImageNode[] = data.allFile.edges || []
  const carouselImages = nodes.map(n => {
    return {
      title: n.node.name,
      imageData: getImage(n.node.childImageSharp),
    }
  })

  return (
    <Layout>
      <Seo title="Home" />
      <Container maxWidth="md" disableGutters={isSmallScreen ? true : false}>
        <Paper className={classes.paper} elevation={isSmallScreen ? 0 : 1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1" align="center">
                “Family gives you the roots to stand tall and strong”
              </Typography>
              <Divider className={classes.divider} variant="middle" />
            </Grid>
            <Grid item xs={12}>
              <Carousel items={carouselImages} />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>
                At the Crowthorne/Sunninghill Christian School Family we
                understand the importance of belonging.  A child that is
                nurtured and secure will flourish in learning and development.
                We all know that our families are the first units in which we
                learn to function. In our society there are so many challenges
                facing us all and one of them is the nourishing of our precious
                children.
              </Typography>
              <Typography gutterBottom>
                We want our school to be like family away from home –{" "}
                <i>intimate</i>, <i>secure</i> and a place where children can{" "}
                <i>thrive</i>. We want our learners to prosper and develop into
                capable and dependable members of our society. <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Container maxWidth="sm">
                <Typography
                  variant="h6"
                  component="h2"
                  gutterBottom
                  align="center"
                >
                  Our vision is simple:
                </Typography>
                <Typography gutterBottom>
                  Nurturing our children to become responsible, well-educated
                  adults who find God’s calling for their lives and make this
                  world a better place.
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} className={classes.adsWrapper}>
              <Typography variant="h6" component="h3" gutterBottom>
                Associated Schools
              </Typography>
              <Grid
                container
                spacing={1}
                alignContent="stretch"
                alignItems="stretch"
                justifyContent="center"
                className={classes.adsGridContainer}
              >
                <Grid item md={4} sm={12}>
                  <Card className={classes.adCard}>
                    <a
                      href="http://www.sandton-akademie.co.za/1%20mpk/mpk-tuis.htm"
                      aria-label="Crowthorne Toddlers"
                    >
                      <StaticImage
                        src="../images/crowthorne-toddlers-add.jpg"
                        alt="Crowthorne Toddlers"
                        placeholder="blurred"
                      />
                    </a>
                  </Card>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Card className={classes.adCard}>
                    <a
                      href="http://www.ccacademy.co.za/"
                      aria-label="Crowthorne Christian Academy"
                    >
                      <StaticImage
                        src="../images/crowthorne-add.jpg"
                        alt="Crowthorne Christian Academy"
                        placeholder="blurred"
                      />
                    </a>
                  </Card>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Card className={classes.adCard}>
                    <a
                      href="http://www.sunninghill-kleuters.co.za/"
                      aria-label="Sunninghill Toddlers"
                    >
                      <StaticImage
                        src="../images/sunninghill-toddlers-add.jpg"
                        alt="Sunninghill Toddlers"
                        placeholder="blurred"
                      />
                    </a>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
  query indexQuery {
    allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "carousel" }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 1280
              formats: AUTO
              transformOptions: { cropFocus: ENTROPY }
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`
