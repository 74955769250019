import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper"
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation])

interface iCarouselProps {
  items?: iCarouselItem[]
}

interface iCarouselItem {
  title?: string
  imageData?: IGatsbyImageData
}

export default function Carousel(props: iCarouselProps): JSX.Element | null {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = makeStyles({
    root: {
      padding: theme.spacing(0.5, 0.5, 2, 0.5),
      height: isSmallScreen ? 320 : 550,
      "&": {
        "& .swiper": {
          width: "100%",
          height: "100%",
          borderRadius: theme.shape.borderRadius,
          "& .swiper-pagination-bullet-active": {
            backgroundColor: theme.palette.background.default,
          },
          "& .swiper-button-prev, .swiper-button-next": {
            color: theme.palette.background.default,
          },
        },

        "& .swiper-slide": {
          textAlign: "center",
          fontSize: 18,
          background: "#fff",

          /* Center slide text vertically */
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },

        "& .swiper-slide img": {
          borderRadius: theme.shape.borderRadius,
          display: "block",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
    },
  })()
  return (
    <div className={classes.root}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        className="mySwiper"
      >
        {props.items?.map(image => {
          console.log(image)
          return (
            <SwiperSlide>
              {image.imageData ? (
                <GatsbyImage
                  image={image.imageData}
                  alt={image.title || "carousel-image"}
                  objectFit="contain"
                />
              ) : (
                <Typography>{image.title || ""}</Typography>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}
